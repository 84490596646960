import axios from 'axios'

export default {
    get: () => axios.get('awards'),
    getActives: () => axios.get('active-awards'),
    show: (id) => axios.get('awards/'+id+'/show'),
    store: (data) => axios.post('awards/store', data),
    update: (data) => axios.post('awards/update', data),
    delete: (id) => axios.post('awards/delete', id),
    reactive: (data) => axios.post('awards/reactive', data),
    deactive: (data) => axios.post('awards/deactive', data),
    bought: (data) => axios.post('suppliers/awards/bought', data),
    getExchanges: () => axios.get('award-exchanges'),
    paid: (data) => axios.post('award-exchanges/paid', data),
    unpaid: (data) => axios.post('award-exchanges/unpaid', data),
}